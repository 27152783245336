import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AdminService } from '../services/admin.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export const canMatchGuard: CanMatchFn = (route, segments) => {
  const adminService = inject(AdminService);
  
  return adminService.getShoppingConfig$().pipe(
    map((response: any) => {
      const menuList = response.payload.services.map((service: { service_name: string, children?: string[] }) => service.service_name);
      // Verifica se o usuário tem acesso à rota
      if(route.data){
        const url_code = route.data['url_code'];
        const hasMatch = url_code.some((code: string) => menuList.includes(code));
        return hasMatch;
      }
      return false;
    }),
    catchError(() => of(false)) // Em caso de erro, impede o acesso
  );
};