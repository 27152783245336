import { Routes } from '@angular/router';
import { canMatchGuard } from './guard/can-match.guard';
import { UserRoles } from './pages/users/user.config';

export const routes: Routes = [
  {
    path: ':shopping_code',
    children:[
      {
        path: 'login',
        loadComponent: () => import('./pages/login/login.component'),
        data:{
          url_code: ['login']
        }
      },
      {
        path: 'change-password',
        loadComponent: () =>
          import('./pages/login/reset-password/reset-password.component'),
        data:{
          url_code: ['change_password']
        }
      },
      {
        path: '',
        loadComponent: () => import('./components/sidebar/sidebar.component'),
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/dashboard/dashboard.component'),
            data: {
              breadcrumb: 'Dashboard',
              role: [UserRoles.admin.id, UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['dashboard']
            },
          },
          {
            path: 'report',
            loadComponent: () => import('./pages/reports/reports.component'),
            data: {
              breadcrumb: 'Relatórios',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['report']
            },
          },
          {
            path: 'users',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Usuários',
              role: [UserRoles.admin.id],
              url_code: ['users']
            },
            loadChildren: () => import('./pages/users/users.routes'),
          },
          {
            path: 'faq',
            loadComponent: () => import('./pages/faq/faq.component'),
            data: {
              breadcrumb: 'Perguntas Frequentes',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['faq']
            },
          },
          {
            path: 'privacy-term',
            canMatch: [canMatchGuard],
            loadComponent: () =>
              import('./pages/privacy-term/privacy-term.component'),
            data: {
              breadcrumb: 'Termos de Privacidade',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['terms']
            },
          },
          {
            path: 'shopping-profile',
            canMatch: [canMatchGuard],
            loadComponent: () =>
              import('./pages/shopping-profile/shopping-profile.component'),
            data: {
              breadcrumb: 'Perfil do Shopping',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['shopping_info']
            },
          },
          {
            path: 'banners',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Banners',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['banner']
            },
            children: [
              {
                path: '',
                data: {
                  breadcrumb: 'Banner Principal',
                  type: 'main',
                  sub_url_code: ['banner_main']
                },
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import('./pages/banners/banners.component'),
                  },
                  {
                    path: 'add',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Novo Banner',
                    },
                  },
                  {
                    path: 'update/:id',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Atualizar Banner',
                    },
                  },
                ],
              },
              {
                path: 'sec',
                data: {
                  breadcrumb: 'Banner Secundário',
                  type: 'sec',
                  sub_url_code: ['banner_sec']
                },
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import('./pages/banners/banners.component'),
                  },
                  {
                    path: 'add',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Novo Banner',
                    },
                  },
                  {
                    path: 'update/:id',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Atualizar Banner',
                    },
                  },
                ],
              },
              {
                path: 'popup',
                data: {
                  breadcrumb: 'Banner Pop-Up',
                  type: 'popup',
                  sub_url_code: ['banner_popup']
                },
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import('./pages/banners/banners.component'),
                  },
                  {
                    path: 'add',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Novo Banner',
                    },
                  },
                  {
                    path: 'update/:id',
                    loadComponent: () =>
                      import('./pages/banners/new-banner/new-banner.component'),
                    data: {
                      breadcrumb: 'Atualizar Banner',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'stores',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Lojas',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['store']
            },
            children: [
              {
                path: '',
                loadComponent: () => import('./pages/stores/stores.component'),
              },
              {
                path: 'add',
                loadComponent: () => import('./pages/stores/new-store/new-store.component'),
                data: {
                  breadcrumb: 'Nova Loja',
                },
              },
              {
                path: 'update/:id',
                loadComponent: () => import('./pages/stores/new-store/new-store.component'),
                data: {
                  breadcrumb: 'Atualizar Loja',
                },
              },
            ],
          },
          {
            path: 'offers',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Ofertas',
              role: [UserRoles.publisher.id, UserRoles.storeAdmin.id, UserRoles.criador.id],
              url_code: ['offer_publisher', 'offer_store']
            },
            children: [
              {
                path: '',
                data: {
                  breadcrumb: 'Ofertas',
                  type: 'offer',
                  sub_url_code: ['all_offers']
                },
                loadChildren: () => import('./pages/offers/offers.routes'),
              },
              {
                path: 'group',
                data: {
                  breadcrumb: 'Promoções Conjuntas',
                  type: 'group',
                  sub_url_code: ['group_offers']
                },
                loadChildren: () =>
                  import('./pages/offers/group-offers/group-offers.routes'),
              },
            ],
          },
          {
            path: 'redeem',
            loadComponent: () => import('./pages/offers/redeem/redeem.component'),
            data: {
              breadcrumb: 'Leitor de ofertas',
              url_code: ['scanner_offer']
            },
          },
          {
            path: 'events',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Eventos',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['event']
            },
            children: [
              {
                path: '',
                data: {
                  breadcrumb: 'Eventos com Ticket',
                  hasTicket: true,
                  sub_url_code: ['event_ticket']
                },
                loadChildren: () => import('./pages/events/events.routes'),
              },
              {
                path: 'no-ticket',
                data: {
                  breadcrumb: 'Eventos sem Ticket',
                  hasTicket: false,
                  sub_url_code: ['event_no_ticket']
                },
                loadChildren: () => import('./pages/events/events.routes'),
              },
            ],
          },
          {
            path: 'notifications',
            canMatch: [canMatchGuard],
            data: {
              breadcrumb: 'Notificações',
              role: [UserRoles.publisher.id, UserRoles.criador.id],
              url_code: ['notification']
            },
            children: [
              {
                path: '',
                data: {
                  breadcrumb: 'Notificações Internas',
                  type: 'internal',
                  sub_url_code: ['notification_internal']
                },
                loadChildren: () =>
                  import('./pages/notifications/notifications.routes'),
              },
              {
                path: 'push-notification',
                data: {
                  breadcrumb: 'Push Notifications',
                  type: 'push_notification',
                  sub_url_code: ['push_notification']
                },
                loadChildren: () =>
                  import('./pages/notifications/notifications.routes'),
              },
            ],
          },
          {
            path: 'event-manager',
            data: {
              breadcrumb: 'Gerenciar Eventos',
              role: [UserRoles.promoter.id],
              url_code: ['event_manager']
            },
            loadComponent: () =>
              import('./pages/event-manager/event-manager.component'),
          },
        ],
      },
    ]
  },

  {
    path: '',
    loadComponent: () => import('./pages/default-page/default-page.component'),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
